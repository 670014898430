<!-- shows a list of favorite wells saved from multisearch -->
<template>

  <v-container :fluid="false">
    <h1 class="subheading blue-grey--text">Bookmarks</h1>
    <div>

      <v-btn @click='pinDrops()' tile outlined block class="my-1" color="blue-grey darken-1" v-if="saveList.length">Map</v-btn>      
      <v-btn @click='saveLocalList()' tile outlined block class="my-1" color="blue-grey darken-1" v-if='this.flashMessage.msg && this.flashMessage.type == "info"'>Save</v-btn>      
                 
      <v-alert :type=flashMessage.type v-if=flashMessage.msg class='font-weight-medium' dense>        
              {{flashMessage.msg}}
      </v-alert>

      <h3 v-if="!saveList.length" class="blue-grey--text">Currently no items saved in your bookmarked items</h3>

      <div v-if="saveList.length" class="">        

        <v-layout wrap  justify-end class="px-4 py-2" :hidden=true >
          <v-flex xs8 sm4 md4 class="py-1">
            <div class="caption grey--text">UWI</div>
          </v-flex>
          <v-flex xs6 sm4 md4 class="hidden-xs-only">  
            <div class="caption grey--text" >LSD</div>  
          </v-flex>
          <v-flex xs4 sm4 md4>
            <div caption class="caption grey--text mx-6">Action</div>  
          </v-flex>
        </v-layout>

        <v-layout v-for="(saveList, index) in saveList" :key='index' class='px-4 py-1' :class="{'blue-grey lighten-4': index%2}" >    
          <v-flex xs8 sm4 md4 >
            <div class="body-2" >{{saveList.uwi}}</div>
          </v-flex>
          <v-flex xs6 sm4 md4 class="hidden-xs-only">
            <div class="body-2">{{saveList.surfloc}}</div>

          </v-flex>
          <v-flex xs4 sm4 md4 class="mt-n1">
            <v-btn style="background-color: transparent !important;" title="Removes a single item" class='mx-4 white' x-small text icon color="success lighten-2" @click.native.stop  @click='removeOne(saveList ,index)'><v-icon size="24" >mdi-check-bold</v-icon></v-btn>
            <v-btn style="background-color: transparent !important;" title="Map a single item" class='mx-0 white' x-small text icon color="bliue-grey darken-3" @click.native.stop  @click='mapOne(saveList ,index)'><v-icon size="24" >mdi-map-marker-radius</v-icon></v-btn>
          </v-flex>              

        </v-layout> 

      </div>

    </div>

  </v-container>

</template>

<script>

export default {
  data(){
    return{

      // SAVELIST SAVED TO LOCALSTARAGE.SAVELIST
      flashMessage: {msg:'', type:'info'},
    }
  },
  computed: {
    saveList(){
      return this.$store.state.saveList;
    }
  },
  methods: {
    saveLocalList(){
      
      localStorage.setItem('saveList', JSON.stringify(this.$store.state.saveList))
      this.flashMessage = {msg: "Your list has been updated and saved", type: "success"}
    },
    mapOne: function(saveList, index){
      this.$router.push({name: 'Mapp', params: { pins: this.$store.state.saveList.splice(index, 1), zoom: 12}})
    },
    pinDrops: function(){
      this.$router.push({name: 'Mapp', params: { pins: this.$store.state.saveList, zoom: 12} })
    },
       
    // REMOVE A SINGLE WELL IN THE SEARCH ARRAY AND UPDATES LOCALSTORAGE
    removeOne: function(saveList, index){  

      this.$store.dispatch('simpleArrayRemove', index)
      this.flashMessage = {msg: 'You have modified your bookmarks, please click save to make this permanent or refresh the page to remove changes', type: 'info'}
    },

  },
  watch: {

  },
  beforeCreate(){
    let user = JSON.parse(localStorage.userInfo).user
    if (!user){

      this.$router.push('/')
    }    
  },
  created(){},
  beforeMount(){},
  mounted(){
    if (localStorage.saveList){
      this.$store.state.saveList = JSON.parse(localStorage.getItem('saveList'));
    }
  },
  beforeUpdate(){},
  updated() {
    
  },

}
</script>

<style>
#help_button{
  left:85px;
  top:-243px;
  
}

</style>